<template>
  <Modal v-model="modal" width="800" title="批量设置媒体的商品附加描述" footer-hide>
    <Table size="small" stripe :data="list" :columns="tableColumns" @on-selection-change="selectionChange"></Table>

    <Row class="text-center">
      <i-col span="24">
        <!-- <i-button class="m-t-10" :disabled="checkServiceItem.length===0" type="primary" @click="handleAdd">提交并保存</i-button> -->
        <i-button class="m-t-10" type="primary" @click="asyncOK(false)">保存设置</i-button>
        <i-button class="m-t-10 m-l-10" type="success" @click="asyncOK(true)">提交并投放</i-button>
      </i-col>
    </Row>
  </Modal>
</template>

<script>
import { getAdditional, addInventoryAdditional } from '@/api/product/cloudinventory'

export default {
  data () {
    return {
      modal: false,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      list: [],
      tableColumns: [
        { type: 'selection', width: 60, align: 'center', fixed: 'left' },
        { title: '编号', key: 'id', width: 100 },
        { title: '标题', key: 'name', width: 200 },
        { title: '内容', key: 'content', tooltip: true }
      ],

      saveBean: {
        additionalIds: null,
        inventoryIds: null,
        isDeliver: false
      }
    }
  },
  methods: {
    showModal (inventoryIds) {
      this.modal = true
      this.saveBean.additionalIds = []
      this.saveBean.inventoryIds = inventoryIds

      getAdditional({ publisherId: this.publisherId }).then(res => {
        this.list = res
      })
    },
    selectionChange (selection) {
      this.saveBean.additionalIds = selection.map(x => x.id)
    },
    asyncOK (isDeliver) {
      this.saveBean.isDeliver = isDeliver
      const successMsg = isDeliver ? '并成功投放，请注意查看商品投放状态！' : ''

      addInventoryAdditional(this.saveBean).then(res => {
        this.modal = false
        this.$Notice.success({ desc: '批量设置商品描述成功！' + successMsg, title: '操作成功' })
        this.$emit('on-success')
      })
    }
  }
}
</script>
